import React from 'react'
import { Link } from 'gatsby'
import { Grid, Message, Icon } from 'semantic-ui-react'

import SEO from '../../components/SEO'

import Logo from '../../images/logos/contego-logo.png'

const ThankYou = () => {
  return (
    <div className="row py-4 px-2">
      <SEO />

      <div className="col ">
        <Grid columns="1" stackable className="my-2 rounded">
          <Grid.Column verticalAlign="middle">
            <img
              src={Logo}
              alt="contego logo"
              className="mx-auto d-block"
              width="150px"
              height="auto"
            />

            <br />

            <h1 className="text-center text-secondary">THANK YOU</h1>

            <Message
              success
              content="Cảm ơn anh/chị đã quan tâm tới sản phẩm của CONTÉGO. Nhân viên tư vấn sẽ liên lạc với
              quý anh chị sớm nhất có thể."
            />

            <br />

            <div className="row">
              <div className="col px-0">
                <div className="py-4 d-flex flex-wrap py-4 px-2 justify-content-center">
                  <a href="https://www.contegospa.com/privacy" className="my-4 mr-4">
                    Privacy Policy
                  </a>
                  <a href="https://www.contegospa.com/gallery" className="my-4 mr-4">
                    Showcases
                  </a>
                  <a href="https://www.contegospa.com/digital-catalog/" className="my-4 mr-4">
                    Digital Catalog
                  </a>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}

export default ThankYou
